import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IRichTextBlock } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import { RichTextModel, richTextModel } from 'models/contentful/richText/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toRichText: ContentModeller<IRichTextBlock, RichTextModel> = ({
  content,
}) => {
  // We impose very loose type checking here as rendering rich
  // text falls within the remit of Contentful's own libraries.
  const model = {
    content: content.fields.content,
    blockType: 'RichText',
  } as RichTextModel;

  return pipe(
    richTextModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'RichText',
        id: content.sys.id,
        fallback: null,
      }),
      m => m as RichTextModel
    )
  );
};

export default toRichText;
