import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IPicture } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { generateLocalizedUrl } from 'models/contentful/link/serializers';
import toMedia from 'models/contentful/media/serializers';
import { PictureModel, pictureModel } from 'models/contentful/picture/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toPicture: ContentModeller<IPicture, PictureModel> = ({
  content,
  locale,
}) => {
  const model = {
    caption: content.fields.caption ?? null,
    size: content.fields.size,
    link: generateLocalizedUrl(content.fields.link, locale),
    picture: content.fields.picture
      ? toMedia({ content: content.fields.picture, locale })
      : null,
    blockType: 'Picture',
  } as PictureModel;

  return pipe(
    pictureModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Picture',
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toPicture;
