import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IDashHudsonUgc } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  DashHudsonUGCModel,
  dashHudsonUGCModel,
} from 'models/contentful/dashHudsonUGC/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toDashHudsonUGC: ContentModeller<IDashHudsonUgc, DashHudsonUGCModel> = ({
  content,
}) => {
  const model = {
    galleryId: content.fields.galleryId,
    rowSize: content.fields.rowSize || '4',
    gapSize: content.fields.gapSize || '4',
    rowLimit: content.fields.rowLimit || 'auto',
    mobileRowSize: content.fields.mobileRowSize || '4',
    mobileGapSize: content.fields.mobileGapSize || '2',
    blockType: 'DashHudsonUGC',
  } as DashHudsonUGCModel;

  return pipe(
    dashHudsonUGCModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'DashHudsonUGC',
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toDashHudsonUGC;
