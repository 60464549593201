import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { ITwoColumnsListing } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import toHeader from 'models/contentful/header/serializers';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  TwoColumnsListingModel,
  twoColumnsListingModel,
} from 'models/contentful/twoColumnsListing/types';

const toTwoColumnsListing: ContentModeller<
  ITwoColumnsListing,
  TwoColumnsListingModel
> = ({ content, locale }) => {
  const model = {
    header: toHeader({ content: content.fields.header, locale }),
    content: [
      content.fields.contentOne,
      content.fields.contentTwo ?? null,
      content.fields.contentThree ?? null,
      content.fields.contentFour ?? null,
    ],
    blockType: 'TwoColumnsListing',
  } as TwoColumnsListingModel;

  return pipe(
    twoColumnsListingModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'TwoColumnsListing',
        id: content.sys.id,
        fallback: null,
      }),
      m => m as TwoColumnsListingModel
    )
  );
};

export default toTwoColumnsListing;
