import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IHero, ISplitHero } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import toHero from 'models/contentful/hero/serializers';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  SplitHeroModel,
  splitHeroModel,
} from 'models/contentful/splitHero/types';

const toSplitHero: ContentModeller<ISplitHero, SplitHeroModel> = ({
  content,
  locale,
}) => {
  const slots = content.fields.slots
    ?.map(content => toHero({ content: content as IHero, locale }))
    .filter(slot => !!slot); // filter out nulls
  const model = {
    title: content.fields.title ?? '',
    slots,
    delaySeconds: content.fields.delaySeconds,
    blockType: 'SplitHero',
  } as SplitHeroModel;

  return pipe(
    splitHeroModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'SplitHero',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toSplitHero;
