import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IGrapheneHcRecommendationsGrid } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  grapheneHcRecommendationsGridModel,
  GrapheneHcRecommendationsGridModel,
} from 'models/contentful/grapheneHcRecommendationsGrid/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toGrapheneHcRecommendationsGrid: ContentModeller<
  IGrapheneHcRecommendationsGrid,
  GrapheneHcRecommendationsGridModel
> = ({ content }) => {
  const model = {
    blockType: 'GrapheneHcRecommendationsGrid',
    pageElementId: content.fields.pageElementId,
    title: content.fields.title ?? '',
    openQuickView: Boolean(content.fields.openQuickView),
  } as GrapheneHcRecommendationsGridModel;

  return pipe(
    grapheneHcRecommendationsGridModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Contributors',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toGrapheneHcRecommendationsGrid;
