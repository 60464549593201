import { ProductProjection } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IProductRail } from 'generated/contentful';
import { buildInlineListings } from 'lib/contentful/buildInlineListings';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  ProductRailModel,
  productRailModel,
} from 'models/contentful/productRail/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toProductRail: ContentModeller<
  IProductRail,
  ProductRailModel,
  { products: Record<string, ProductProjection> }
> = ({
  content: {
    sys: { id },
    fields: { productImageToDisplay, skus, title },
  },
  locale,
  products,
}) => {
  const model = {
    title: title ?? '',
    products: buildInlineListings({
      skus,
      products,
      productImageToDisplay,
      locale,
    }),
    blockType: 'ProductRail',
  } as ProductRailModel;

  return pipe(
    productRailModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'ProductRail',
        id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toProductRail;
