import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IStories } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { ContentModeller } from 'models/contentful/shared/types';
import { StoriesModel, storiesModel } from 'models/contentful/stories/types';
import toStorySource from 'models/contentful/storySource/serializers';

const toStories: ContentModeller<IStories, StoriesModel> = ({
  content,
  locale,
}) => {
  const model = {
    storySource: content.fields.storySource.map(storySource =>
      toStorySource({
        content: storySource,
        locale,
      })
    ),
    columnWidth: content.fields.columnWidth,
    blockType: 'Stories',
  } as StoriesModel;

  return pipe(
    storiesModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Stories',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toStories;
