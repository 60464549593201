import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { ISizeGuideTab } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  SizeGuideTabModel,
  sizeGuideTabModel,
} from 'models/contentful/sizeGuideTab/types';
import toSizeGuideTable from 'models/contentful/sizeGuideTable/serializers';

const toSizeGuideTab: ContentModeller<ISizeGuideTab, SizeGuideTabModel> = ({
  content,
  locale,
}) => {
  const model = {
    tabName: content.fields.tabName,
    howToMeasureImage: content.fields.howToMeasureImage
      ? { url: content.fields.howToMeasureImage.fields.file.url }
      : null,
    howToMeasureDescription: content.fields.howToMeasureDescription
      ? content.fields.howToMeasureDescription
      : null,
    tables: content.fields.tables?.map(table =>
      toSizeGuideTable({ content: table, locale })
    ),
    blockType: 'SizeGuideTab',
  } as SizeGuideTabModel;

  return pipe(
    sizeGuideTabModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'SizeGuideTab',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toSizeGuideTab;
