import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IHero, IHeroCarousel, IHeroHalf } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import toHero from 'models/contentful/hero/serializers';
import {
  HeroCarouselModel,
  heroCarouselModel,
} from 'models/contentful/heroCarousel/types';
import toHeroHalf from 'models/contentful/heroHalf/serializers';
import { ContentModeller } from 'models/contentful/shared/types';

const toHeroCarousel: ContentModeller<IHeroCarousel, HeroCarouselModel> = ({
  content,
  locale,
}) => {
  const slots = content.fields.slots
    ?.map(content => {
      switch (content.sys.contentType.sys.id) {
        case 'hero':
          return toHero({ content: content as IHero, locale });
        case 'heroHalf':
          return toHeroHalf({ content: content as IHeroHalf, locale });
        default:
          return null;
      }
    })
    .filter(slot => !!slot); // filter out nulls
  const model = {
    slots,
    delaySeconds: content.fields.delaySeconds,
    blockType: 'HeroCarousel',
  } as HeroCarouselModel;

  return pipe(
    heroCarouselModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'HeroCarousel',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toHeroCarousel;
