import { ProductProjection } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IProductMosaic } from 'generated/contentful';
import { buildInlineListings } from 'lib/contentful/buildInlineListings';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import toMedia from 'models/contentful/media/serializers';
import {
  productMosaicModel,
  ProductMosaicModel,
} from 'models/contentful/productMosaic/types';
import { ContentModeller } from 'models/contentful/shared/types';
import toStorySource from 'models/contentful/storySource/serializers';

const toProductMosaic: ContentModeller<
  IProductMosaic,
  ProductMosaicModel,
  { products: Record<string, ProductProjection> }
> = ({
  content: {
    sys: { id },
    fields: {
      displayMode,
      mobileDisplayMode,
      media,
      productImageToDisplay,
      skus,
      storySource,
    },
  },
  locale,
  products,
}) => {
  const model = {
    displayMode,
    mobileDisplayMode: mobileDisplayMode || displayMode,
    products: buildInlineListings({
      skus,
      products,
      productImageToDisplay,
      locale,
    }),
    storySource: storySource
      ? toStorySource({ content: storySource, locale })
      : null,
    media: media ? toMedia({ content: media, locale }) : null,
    blockType: 'ProductMosaic',
  } as ProductMosaicModel;

  const missingProducts = skus
    .filter(sku => products[sku] === undefined)
    .join(', ');

  return pipe(
    productMosaicModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'ProductMosaic',
        id: `${id}${
          missingProducts ? ': missing products ' + missingProducts : ''
        }`,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toProductMosaic;
