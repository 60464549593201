import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IStoriesOverlay } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  StoriesOverlayModel,
  storiesOverlayModel,
} from 'models/contentful/storiesOverlay/types';
import toStorySource from 'models/contentful/storySource/serializers';

const toStoriesOverlay: ContentModeller<
  IStoriesOverlay,
  StoriesOverlayModel
> = ({ content, locale }) => {
  const model = {
    storySource: content.fields.storySource.map(storySource =>
      toStorySource({
        content: {
          ...storySource,
          fields: {
            ...storySource.fields,
            textColor: storySource.fields.textColor ?? 'white',
          },
        },
        locale,
      })
    ),
    columnWidth: content.fields.columnWidth,
    blockType: 'StoriesOverlay',
  } as StoriesOverlayModel;

  return pipe(
    storiesOverlayModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'StoriesOverlay',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toStoriesOverlay;
