import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { ISizeGuideTable } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { ContentModeller } from 'models/contentful/shared/types';
import {
  sizeGuideTableModel,
  SizeGuideTableModel,
} from 'models/contentful/sizeGuideTable/types';

const toSizeGuideTable: ContentModeller<
  ISizeGuideTable,
  SizeGuideTableModel
> = ({ content }) => {
  const model = {
    title: content.fields.tableTitle || null,
    tableDataCm: content.fields.tableDataCm,
    tableDataIn: content.fields.tableDataIn,
    showUnitTabs: content.fields.showUnitTabs,
  } as SizeGuideTableModel;

  return pipe(
    sizeGuideTableModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'SizeGuideTable',
        id: content.sys?.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toSizeGuideTable;
