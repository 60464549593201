import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IContributors } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  contributorsModel,
  ContributorsModel,
} from 'models/contentful/contributors/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toContributors: ContentModeller<IContributors, ContributorsModel> = ({
  content,
}) => {
  const model = {
    blockType: 'Contributors',
    items: content.fields.contributors?.map(contributor => contributor.fields),
  } as ContributorsModel;

  return pipe(
    contributorsModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Contributors',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toContributors;
