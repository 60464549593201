import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IGrapheneHcRecommendationsCarousel } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  grapheneHcRecommendationsCarouselModel,
  GrapheneHcRecommendationsCarouselModel,
} from 'models/contentful/grapheneHcRecommendationsCarousel/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toGrapheneHcRecommendationsCarousel: ContentModeller<
  IGrapheneHcRecommendationsCarousel,
  GrapheneHcRecommendationsCarouselModel
> = ({ content }) => {
  const model = {
    blockType: 'GrapheneHcRecommendationsCarousel',
    pageElementId: content.fields.pageElementId,
  } as GrapheneHcRecommendationsCarouselModel;

  return pipe(
    grapheneHcRecommendationsCarouselModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Contributors',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toGrapheneHcRecommendationsCarousel;
