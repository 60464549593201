import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { ISizeGuideTabs } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { ContentModeller } from 'models/contentful/shared/types';
import toSizeGuideTab from 'models/contentful/sizeGuideTab/serializers';
import {
  SizeGuideTabsModel,
  sizeGuideTabsModel,
} from 'models/contentful/sizeGuideTabs/types';

const toSizeGuideTabs: ContentModeller<ISizeGuideTabs, SizeGuideTabsModel> = ({
  content,
  locale,
}) => {
  const model = {
    introductionSection: content.fields.introductionSection,
    tabs: content.fields.tabs.map(tab =>
      toSizeGuideTab({ content: tab, locale })
    ),
    blockType: 'SizeGuideTabs',
  } as SizeGuideTabsModel;

  return pipe(
    sizeGuideTabsModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'SizeGuideTabs',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toSizeGuideTabs;
