import { ProductProjection } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IProductGrid } from 'generated/contentful';
import { buildInlineListings } from 'lib/contentful/buildInlineListings';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import {
  ProductGridModel,
  productGridModel,
} from 'models/contentful/productGrid/types';
import { ContentModeller } from 'models/contentful/shared/types';

const toProductGrid: ContentModeller<
  IProductGrid,
  ProductGridModel,
  {
    products: Record<string, ProductProjection>;
    productsByCategory: Record<string, string[]>;
  }
> = ({
  content: {
    sys: { id },
    fields: { categoryKey, gridWidth, productImageToDisplay },
  },
  locale,
  products,
  productsByCategory,
}) => {
  const model = {
    blockType: 'ProductGrid',
    gridWidth,
    products: buildInlineListings({
      skus: productsByCategory[categoryKey],
      products,
      productImageToDisplay,
      categoryKey,
      locale,
    }),
  } as ProductGridModel;

  return pipe(
    productGridModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'ProductGrid',
        id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toProductGrid;
