import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import {
  IHero,
  IHeroFields,
  IStorySource,
  IStorySourceFields,
} from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { HeroModel, heroModel } from 'models/contentful/hero/types';
import { ContentModeller } from 'models/contentful/shared/types';
import toStorySource from 'models/contentful/storySource/serializers';

export const toHeroTextColor = (
  textColor: IStorySourceFields['textColor'],
  displayMode: IHeroFields['displayMode']
): IStorySourceFields['textColor'] => {
  if (textColor) {
    return textColor;
  }

  switch (displayMode) {
    case 'overlay':
    case 'overlay bottom':
      return 'white';

    default:
      return 'black';
  }
};

const toHero: ContentModeller<IHero, HeroModel> = ({ content, locale }) => {
  const displayMode = content.fields.displayMode ?? 'overlay';
  const ctaStyle = content.fields.ctaStyle ?? 'underline';

  const storySource = toStorySource({
    content: {
      ...(content.fields.storySource as IStorySource),
      fields: {
        ...(content.fields.storySource as IStorySource)?.fields,
        textColor: toHeroTextColor(
          content.fields.storySource?.fields.textColor,
          displayMode
        ),
      },
    },
    locale,
  });

  const model = {
    storySource,
    displayMode,
    ctaStyle,
    blockType: 'Hero',
  } as HeroModel;

  return pipe(
    heroModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Hero',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toHero;
