import { ProductProjection } from '@commercetools/platform-sdk';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IOutfit } from 'generated/contentful';
import { buildInlineListings } from 'lib/contentful/buildInlineListings';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { OutfitModel, outfitModel } from 'models/contentful/outfit/types';
import { ContentModeller } from 'models/contentful/shared/types';
import toStorySource from 'models/contentful/storySource/serializers';

const toOutfit: ContentModeller<
  IOutfit,
  OutfitModel,
  { products: Record<string, ProductProjection> }
> = ({
  content: {
    sys: { id },
    fields: {
      productImageToDisplay,
      productRail: {
        fields: { skus },
      },
      storySource,
    },
  },
  products,
  locale,
}) => {
  const model = {
    blockType: 'Outfit',
    storySource: toStorySource({
      content: storySource,
      locale,
    }),
    products: buildInlineListings({
      skus,
      products,
      productImageToDisplay,
      locale,
    }),
  } as OutfitModel;

  return pipe(
    outfitModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Outfit',
        id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toOutfit;
