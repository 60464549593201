import { ProductProjection } from '@commercetools/platform-sdk';
import { toProductListing } from 'models/productListing/serializers';
import { ProductListingModel } from 'models/productListing/types';

type BuildInlineListings = (args: {
  skus?: string[];
  products: Record<string, ProductProjection>;
  productImageToDisplay?: keyof typeof productImageToDisplayMapping;
  categoryKey?: string;
  locale: string;
  allowOutOfStock?: boolean;
}) => ProductListingModel[];

const productImageToDisplayMapping = {
  Model: 'model',
  'Still Life': 'stillLife',
} as const;

export const buildInlineListings: BuildInlineListings = ({
  skus = [],
  products: productProjections,
  productImageToDisplay = 'Model',
  categoryKey,
  locale,
  allowOutOfStock = true,
}) => {
  const productListings: ProductListingModel[] = [];

  for (const sku of skus) {
    if (productProjections[sku]) {
      const productListing = toProductListing({
        product: productProjections[sku],
        productImageToDisplay:
          productImageToDisplayMapping[productImageToDisplay],
        categoryKey,
        locale,
        allowOutOfStock,
      });

      if (productListing) {
        productListings.push(productListing);
      }
    }
  }

  return productListings;
};
