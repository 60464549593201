import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IHeroHalf } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { HeroHalfModel, heroHalfModel } from 'models/contentful/heroHalf/types';
import { ContentModeller } from 'models/contentful/shared/types';
import toStorySource from 'models/contentful/storySource/serializers';

const toHeroHalf: ContentModeller<IHeroHalf, HeroHalfModel> = ({
  content,
  locale,
}) => {
  const model = {
    storySource: toStorySource({
      content: {
        ...content.fields.storySource,
        fields: {
          ...content.fields.storySource.fields,
          textColor: content.fields.storySource.fields.textColor ?? 'black',
        },
      },
      locale,
    }),
    backgroundColor: content.fields.backgroundColor ?? 'white',
    displayMode: content.fields.displayMode,
    blockType: 'HeroHalf',
  } as HeroHalfModel;

  return pipe(
    heroHalfModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'HeroHalf',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toHeroHalf;
